%wrap {
    margin-left: auto;
    margin-right: auto;
    padding-left: $wrap-gutter;
    padding-right: $wrap-gutter;

    @include media(l) {
        padding-left: $wrap-gutter-l;
        padding-right: $wrap-gutter-l;
        max-width: $max-l;
    }

    @include media(xl) {
        padding-left: $wrap-gutter-xl;
        padding-right: $wrap-gutter-xl;
        max-width: $max-xl;
    }

    @include media(xxl) {
        padding-left: $wrap-gutter-xxl;
        padding-right: $wrap-gutter-xxl;
        max-width: $max-xxl;
    }

    &-content {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: $max-content;
    }

    @media print {
        padding-left: $wrap-gutter;
        padding-right: $wrap-gutter;
        max-width: none;
    }
}
