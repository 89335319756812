@import 'styles/includes.scss';

.Root {
    position: relative;
}

.Container {
    position: absolute;
    width: 100%;
}

.Content {
    @extend %wrap;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    padding-top: 24px;
    padding-bottom: 24px;
    flex-direction: column;

    @include media(xl) {
        justify-content: flex-start;
    }
}

.Logo {
    z-index: $z-logo;

    svg * {
        transform: scale(0.8);
        fill: $color-dark-violet;

        .RootLight & {
            fill: $color-white;
        }

        @include media(m) {
            transform: scale(1);
        }
    }
}
