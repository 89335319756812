@import 'styles/includes.scss';

.Root {
    position: absolute;
    width: 100%;

    @include media(m) {
        display: none;
    }
}

.Content {
    width: 100%;
    height: 100vh;
    background-color: $color-dark-violet;
    position: absolute;
    top: 0;
    overflow-y: auto;
    width: 100%;
    pointer-events: none;
    z-index: $z-menu-content;
    display: none;

    .RootActive & {
        display: flex;
        pointer-events: auto;
    }
}

.List {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    top: -40px;
}

.Item {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Link {
    @include h2-headline;
    display: block;
    color: $color-white;
    text-decoration: none;
    transition: opacity 0.4s cubic-bezier(0.2, 1, 0.22, 1);
    transition-delay: 0.2s;

    .ItemCurrent & {
        text-decoration: underline;
    }

    &:hover {
        text-decoration: underline;

        @media (hover: none) {
            text-decoration: none;
        }
    }
}

.Button {
    position: absolute;
    top: 0;
    right: 0;
    height: 64px;
    width: 62px;
    padding: 0;
    color: inherit;
    border: none;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
    z-index: $z-mobilemenu-button;

    &--Active {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
}

.ButtonContainer {
    display: flex;
    align-items: center;
}

.ButtonIcon {
    display: block;
    position: relative;
    width: 40px;
    height: 24px;

    .RootActive & {
        height: 33px;
    }
}

.ButtonDash {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: $color-dark-violet;
    transform-origin: left;
    transition: all 0.25s ease-in-out;

    .ButtonLight & {
        background-color: $color-white;
    }

    .RootActive & {
        background-color: $color-white;
    }

    &:first-child {
        .RootActive & {
            transform: rotate(45deg);
            width: 40px;
        }
    }

    &:nth-child(2) {
        top: calc((100% / 2) - 2px);

        .RootActive & {
            opacity: 0;
        }
    }

    &:last-child {
        bottom: 0;

        .RootActive & {
            transform: rotate(-45deg);
            width: 40px;
        }
    }
}
