@import 'styles/includes.scss';

.Root {
    display: none;
    /* position: relative; */

    @include media(m) {
        display: flex;
    }

    /* @include media(xl) { */
    /*     position: absolute; */
    /*     right: 416px; */
    /* } */
}

.List {
    z-index: $z-menu-content;
    transform: translateX(-6px);
}

.Item {
    position: relative;
    top: 2px;
    display: inline-block;
    overflow: hidden;

    &:not(:last-child) {
        margin-right: 26px;
    }
}

.Link {
    display: block;
    position: relative;
    padding: 5px 6px 7px;
    font-size: 1.6rem;
    font-weight: $medium;
    text-decoration: none;

    color: $color-black;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(black, 0.16);
        opacity: 0;
        overflow: hidden;
        border-radius: 4px;
        transform: translateX(-100%);
        transition: all 600ms cubic-bezier(0.2, 1, 0.22, 1);
    }

    .ItemCurrent &,
    &:hover, &:focus {
        &::after {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .ItemLight & {
        color: $color-dark-violet;
    }

    .ItemWhite & {
        color: white;
    }

    .ItemLight &:after {
        background-color: rgba($color-lavender, 0.16);
    }

    .ItemWhite &:after {
        background-color: rgba(white, 0.16);
    }
}
