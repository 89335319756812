@import 'styles/includes.scss';

.ReglContainer {
    background-color: transparent;
    canvas {
        background-color: transparent;
        filter: blur(0.5px);
        @include media(m) {
            filter: none;
        }
    }
}
