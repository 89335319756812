@import 'styles/includes.scss';

.Root {
}

.BlobWrapper {
    position: absolute;
    top: -570px;
    left: -550px;

    @include media(m) {
        top: -560px;
        left: -540px;
        transform: rotateX(15deg);
    }

    @include media(l) {
        top: -450px;
        left: -460px;
        transform: rotateX(15deg);
    }

    @include media(xxxl) {
        top: -450px;
        left: -260px;
        transform: rotateX(15deg);
    }
}
