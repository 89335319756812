@import 'styles/includes.scss';

.Root {
    position: relative;
    padding-top: 160px;
    padding-bottom: 64px;

    .MenuMobileActive & {
        display: none;
    }

    @include media(l) {
        padding-top: 208px;
        padding-bottom: 224px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 560px;
        bottom: 0;
        background: url(#{$basepath}img/svg/footer-pattern.svg) no-repeat;
        background-position: -200px 0;
        z-index: 0;

        @include media(m) {
            height: 624px;
        }

        @include media(l) {
            height: 800px;
            background-size: 180%;
        }
    }
}

.Top,
.Bottom {
    @extend %wrap;
}

.Top {
    position: relative;
    margin-bottom: 70px;
}

.Bottom {
    position: relative;
    text-align: center;

    @include media(m) {
        text-align: left;
    }
}

.Wysiwyg {
    max-width: 830px;
    text-align: left;
    margin-bottom: 30px;
}

.WysiwygTop {
    font-weight: $bold;
    font-size: 2rem;
    line-height: 1.2;

    a {
        font-weight: $bold;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media(m) {
        font-size: 2.4rem;
        line-height: 1.33;
    }

    @include media(xl) {
        font-size: 3.2rem;
        line-height: 1.25;
    }
}

.WysiwygBottom {
    font-size: 1.4rem;
    line-height: 1.7;

    @include media(m) {
        font-size: 1.8rem;
        line-height: 2;
    }

    a {
        &:hover {
            text-decoration: underline;
            color: $color-dark-violet;
        }
    }
}

.Label {
    margin-bottom: 13px;
    text-align: left;
    font-size: 1.2rem;
    font-weight: $medium;
    letter-spacing: 0.1rem;
}

.Button {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    text-decoration: none;
    font-family: $base-font;

    border-radius: 4px;
    background-color: $color-indigo;
    border: none;

    @include navigation-titles;
    color: $color-white;

    @include media(m) {
        width: auto;
        display: inline-flex;
        height: 56px;
        padding: 0 24px;
    }

    &Light {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.9);
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    &:focus {
        border: 1px dotted #fff;
    }
}
