@import 'styles/includes.scss';

.Root {
}

.Button {
    width: auto;
    height: auto;
    overflow: visible;
    background-color: transparent;
    position: fixed;

    right: 0;
    bottom: 0;

    @include media(l) {
        right: $wrap-gutter;
        bottom: $wrap-gutter;
    }

    outline: none;

    &:focus {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-image: url(#{$basepath}img/svg/icon-pink.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    width: 84px;
    height: 84px;
    border-radius: 50%;
    border: none;
    z-index: $z-modal + 1;

    @include media(m) {
        width: 100px;
        height: 100px;

        bottom: 40%;
    }

    @include media(l) {
        width: 130px;
        height: 130px;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: '';
        position: absolute;

        top: 0;
        left: 0;

        @include media(l) {
            top: -3px;
            left: 3px;
        }

        border-radius: 50%;

        width: 100%;
        height: 100%;

        background-image: url(#{$basepath}img/svg/icon-contact.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: 1;
    }
}

.Button--Active {
    &:focus {
        &:before {
            background-image: url(#{$basepath}img/svg/icon-green-1.svg);
        }
    }

    &:after {
        margin-left: 5px;
        margin-top: 7px;

        @include media(l) {
            margin-left: 11px;
            margin-top: 15px;
        }

        width: 80%;
        height: 80%;

        background-color: $color-white;
        background-image: url(#{$basepath}img/svg/icon-close.svg);
    }
}

.Button--Hidden {
    background-color: $color-indigo;

    &:after {
        opacity: 0;
    }
}

.GrowBobWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.GrowBlob {
    background-color: $color-dark-violet;

    position: absolute;
    min-width: 0;
    min-height: 0;

    border-radius: 50%;
}

.Modal {
    display: none;
}

.Modal--Active {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;

    padding-bottom: 32px;

    overflow: auto;
    top: 0;
    left: 0;

    background: $color-dark-violet;
    z-index: $z-modal + 10;

    &:focus {
        outline: none;
    }
}

.LogoContainer {
    @extend %wrap;
    margin: 20px auto 0 auto;

    @include media(m) {
        margin: 24px auto 0 auto;
    }
}

.Logo {
    height: 40px;
    width: 110px;
    cursor: pointer;
    background-image: url(#{$basepath}img/svg/logo-frojd-white.svg);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 32px;
    border: none;
    background-color: transparent;

    @include media(m) {
        background-size: auto 40px;
    }
}

.ModalContainer {
    color: $color-white;
    padding: $wrap-gutter;

    @include media(m) {
        max-width: 920px;
        margin: 15vh auto 0 auto;
    }

    @include media(l) {
        padding: 0;
    }
}

.Row {
    padding: 36px 0;

    @include media(m) {
        display: flex;
        padding: 44px 0;
    }

    &:first-of-type {
        border-bottom: 1px solid $color-dark-violet;
    }
}

.Column {
    @include media(m) {
        width: 50%;

        &:first-of-type {
            padding-right: 48px;
        }
    }
}

.Title {
    @include h2-headline;
    margin-bottom: 16px;
    font-size: 3.2rem;
    max-width: 418px;

    @include media(m) {
        margin-bottom: 0;
    }
}

.Cta {
    h3 {
        @include small-labels;
        font-weight: 500;
        text-transform: uppercase;
        opacity: 0.8;
        margin-bottom: 18px;
    }

    a {
        color: $color-white;
        @include standard-link;

        &:hover {
            color: $color-weird-green;
        }
    }

    ul, ol {
        margin-top: 16px;
    }

    li {
        position: relative;
        padding-left: 24px;

        &:before {
            position: absolute;
            content: ' ';
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            width: 0;
            height: 0;

            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 12px solid $color-weird-green;
        }
    }
}

.CtaLeft {
    @include body-copy;

    @include media(m) {
        margin-top: 0;
        padding-right: 48px;
        width: 368px;
    }
}

.CtaRight {
    @include body-copy;
    margin-top: 32px;

    @include media(m) {
        margin-top: 0;
        max-width: 342px;
    }
}
