@import 'styles/includes.scss';

.Root {
}

.BlobWrapper {
    position: absolute;

    top: 0px;
    left: -3px;

    @include media(l) {
        top: -3px;
        left: -2px;
    }

    transition: top 300ms ease-out, left 300ms ease-out;
}

.BlobWrapperActive {
    position: absolute;

    top: 3px;
    left: 0;

    @include media(l) {
        top: 6px;
        left: 3px;
    }
}
