@import 'styles/includes.scss';

.Root {
}

.BlobWrapperGreen {
    position: absolute;
    display: none;

    @include media(m) {
        display: block;

        left: -20px;
        top: -500px;
    }

    @include media(ml) {
        right: auto;
        left: 370px;
        top: -200px;
    }

    @include media(l) {
        left: 50%;
        top: -250px;
    }
}

.BlobWrapperPurple {
    position: absolute;

    right: auto;

    left: -290px;
    top: -640px;

    @include media(s) {
        left: -220px;
        top: -640px;
    }

    @include media(sm) {
        left: -130px;
        top: -640px;
    }

    @include media(sl) {
        left: 25px;
    }

    @include media(ml) {
        right: auto;
        left: 25%;
        top: -520px;
    }
}
